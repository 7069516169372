import { useRouter, useRoute } from 'vue-router';

export default function AddParamsToPage() {
  function setParamsToUrl() {
    const router = useRouter();
    const route = useRoute();

    // Récupérer les paramètres existants, en conservant `order-id` et `workshop-id` s'ils existent
    const currentParams = { ...route.query };
    
    // Ajouter/mettre à jour `organizer-id` et `archive-id`
    currentParams["organizer-id"] = useOrganizerStore().organizer.orgaId;
    currentParams["archive-id"] = archiveStore().archiveDefault.id;

    
    if(useOrderOneStore().order.id !== 0)
    currentParams["order-id"] = useOrderOneStore().order.id;
    router.push({
      path: route.path,
      query: currentParams,
    });
  }

  return setParamsToUrl();
}
