<script setup lang="ts">
import { ref, shallowRef } from "vue";
import { useCustomizerStore } from "@/stores/customizer";
import { useOrganizerStore } from "@/stores/organizerStore";
import sidebarItems from "./sidebarItem";
import NaviguationArchive from "./ArchiveMenu.vue";

const customizer = useCustomizerStore();
const organizerStore = useOrganizerStore();
const isHovered = ref(false);
const sidebarMenu = shallowRef(sidebarItems);
const key = ref(0);
const model = ref(false)

const organizer = computed(() => organizerStore.organizer);

const handleMouseEnter = () => {
  isHovered.value = true;
};

const handleMouseLeave = () => {
  isHovered.value = false;
};

const customSizeMenu = () => {
  if (customizer.mini_sidebar) {
    customizer.SET_MINI_SIDEBAR(false);
  }
  
};
</script>

<template>
  <v-navigation-drawer
    left
    v-model="customizer.Sidebar_drawer"
    elevation="10"
    rail-width="70"
    app
    class="leftSidebar ml-lg-5 mt-lg-5 bg-containerBg"
    :rail="customizer.mini_sidebar"
    expand-on-hover
    width="270"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave">
    <div class="pa-5 pl-4 d-flex align-center justify-space-between">
      <LcFullLogo />
      <template v-if="!customizer.mini_sidebar || isHovered">
        <div class="text-h5 w-50">
          {{ organizer.title }}
        </div>
        
        <!-- ---------------------------------------------- -->
        <!---Action DD -->
        <!-- ---------------------------------------------- -->
        <LcFullVerticalHeaderStructureActionDD @menu-state-changed="customSizeMenu" />
      </template>
    </div>

    <!-- ---------------------------------------------- -->
    <!---Archives -->
    <!-- ---------------------------------------------- -->
    <NaviguationArchive
      class="mt-2 mx-4"
      @update="key += 1"
      :show-item-title="!customizer.mini_sidebar || isHovered"
    />

    <!-- ---------------------------------------------- -->
    <!---Navigation -->
    <!-- ---------------------------------------------- -->
    <perfect-scrollbar class="scrollnavbar bg-containerBg overflow-y-hidden">
      <v-list class="py-4 px-4 bg-containerBg">
        <!---Menu Loop -->
        <template v-for="(item, index) in sidebarMenu">
          <!---Item Sub Header -->
          <LcFullVerticalSidebarNavGroup
            v-if="item.header"
            :item="item"
            :key="item.title"
          />
          <!---If Has Child -->
          <LcFullVerticalSidebarNavCollapse
            v-else-if="item.children"
            class="leftPadding"
            :item="item"
            :level="0"
            :key="`collapse-${item.title}`"
          />
          <!---Single Item-->
          <LcFullVerticalSidebarNavItem
            :item="item"
            v-else
            class="leftPadding"
            :key="`item-${index}`"
          />
          <!---End Single Item-->
        </template>
      </v-list>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>
