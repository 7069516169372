<script setup lang="ts">
import { onBeforeMount } from "vue";
import { Icon } from "@iconify/vue";
import { useRuntimeConfig } from "#imports";

/* LOADER HUBSPOT config*/
const config = {
  region: useRuntimeConfig().public.region,
  portalId: useRuntimeConfig().public.hubspotPortalId,
  formId: useRuntimeConfig().public.hubspotFormId
};

const user = useUserStore().user;

console.log("user", user);

onBeforeMount(async () => {
  const scriptHubspot = document.createElement("script");
  scriptHubspot.setAttribute("src", "https://js.hsforms.net/forms/shell.js");
  scriptHubspot.onload = () => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        region : config.region,
        portalId: config.portalId,
        formId: config.formId,
        target: "#hubspotForm",
        onFormReady: function ($form) {
          // URL
          let firstNameField = $form.querySelector('input[name="firstname"]');
          firstNameField.value = user.firstName;

          // URL
          let lastNameField = $form.querySelector('input[name="lastname"]');
          lastNameField.value = user.lastName;

          // URL
          let messageField = $form.querySelector('textarea[name="TICKET.content"]');
          messageField.style.height = "300px";

          //let urlField = $form.find('textarea[name="TICKET.lien_vers_la_fiche_contrat"]');
          let defaultMessage = "Bonjour";
          defaultMessage += "\n\n\Demande : ";
          defaultMessage += "\n\n\Page URL: " + window.location.href;
          messageField.value = defaultMessage;

          // EMAIL
          let emailField = $form.querySelector('input[name="email"]');
          let organizerEmail = user.email;
          // Injecter l'email dans le champ message
          emailField.value = organizerEmail;
        },
      });
    }
  };

  document.head.appendChild(scriptHubspot);
});

</script>

<template>
  <v-card class="scrollable-card">
    <v-card-item>
      <v-alert color="info" variant="tonal">
        <div class="d-flex flex-row align-center">
        <!--AVATAR LOYS with ?-->
        <v-btn
          size="large"
          icon
          variant="flat"
          color="primary"
          style="cursor: default"
          class="my-2 mr-2"
        >
          <div class="avatar-container d-flex">
            <v-avatar size="50" image="/images/profile/Loys.png" />
            <div
              class="icon-overlay d-flex justify-center align-center bg-primary"
            >
              <span class="mdi mdi-help icon-help"></span>
            </div>
          </div>
        </v-btn>

        <!-- TEXTE -->
        <div class="d-flex flex-column mx-3">
          <!-- TITLE -->
          <span class="text-h6 font-weight-semibold">Besoin d'aide ?</span>
          <!-- DESCRIPTION -->
            <span class="text-body-2 mt-2" style="color: black;">
              Envoyez moi un message pour que je puisse vous aider.
            </span>
        </div>
        <!-- ICON-->
        <Icon
              icon="solar:chat-round-dots-linear"
              height="30"
              width="30"
              class="cursor-pointer mr-1"
            />
      </div>
      </v-alert>
      <div v-once id="hubspotForm" class="mt-4 ml-8"></div>
    </v-card-item>
  </v-card>
</template>

<style scoped>
#hubspotForm {
  margin-left: auto;
  margin-right: auto;
}
.scrollable-card {
  max-height: 73vh;
  overflow-y: auto;
}

.icon-overlay {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: none;
}

.icon-help {
  padding-right: 3px;
  padding-bottom: 1px;
}
</style>
