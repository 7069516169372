<script setup lang="ts">
import { ref } from "vue";

const items = ref({
  page : "Ma structure",
  support : [
    {
      title : "Configurer mon compte",
      link : "https://support.viviarto.com/knowledge-base/installation-guide/",
    },
    {
      title : "Modifier sa page école",
      link : "https://support.viviarto.com/knowledge-base/modifier-sa-page-ecole/",
    },
    {
      title : "Modifier les Conditions Générales de Ventes",
      link : "https://support.viviarto.com/knowledge-base/modifier-les-conditions-generales-de-ventes/",
    },
    {
      title : "Paramétrage du logiciel en autonomie",
      link : "https://support.viviarto.com/knowledge-base/parametrage-du-logiciel-en-autonomie/",
    },
  ]
})

</script>

<template>
  <v-card>
    <v-card-item>
      <v-card-title class="mt-2">
        Dans l'onglet {{ items.page }}
      </v-card-title>
      <v-card-text class="pb-2">
        <v-list>
          <v-list-item v-for="(item, index) in items.support" :key="index" class="py-1">
            <NuxtLink class="text-primary text-body-1 text-decoration-none" :to=item.link target="_blank">
              •&nbsp;&nbsp;&nbsp;{{ item.title }} 
              <v-icon small class="mr-2">mdi-arrow-top-right-bold-box-outline</v-icon>
            </NuxtLink>
          </v-list-item>
        </v-list>
      </v-card-text>
      <!-- Section de contact -->
      <div class="d-flex justify-space-between align-center mt-4">
        <span class="text-body-2">
          La réponse n'est pas dans cette liste ?
        </span>
        <v-btn
          color="primary"
          variant="elevated"
          class="ml-3"
          @click="$emit('contactTeam')"
        >
          Contacter l'équipe Viviarto
        </v-btn>
      </div>
    </v-card-item>
  </v-card>
</template>
