<script setup lang="ts">
import { Icon } from "@iconify/vue";

const activeTab = ref(0);
const menuOpen = ref(false);
const userInteraction = ref(false);
const openDelay = 30000;
const closeDelay = 30000;

const closeMenu = () => {
  if (userInteraction.value) {
    return;
  }
  menuOpen.value = false;
};

// onMounted(() => {
//   setTimeout(() => {
//     menuOpen.value = true;
//     setTimeout(() => {
//       menuOpen.value = false;
//     }, closeDelay);
//   }, openDelay);
// });

</script>

<template>
  <!-- ---------------------------------------------- -->
  <!-- Help Center -->
  <!-- ---------------------------------------------- -->
  <v-menu v-model="menuOpen" :close-on-content-click="false" location="end">
    <template v-slot:activator="{ props }">
      <div
        class="custom-hover-primary text-left px-0 cursor-pointer d-flex align-center"
        variant="text"
        v-bind="props"
      >
        <v-btn
          class="customizer-btn"
          size="large"
          icon
          variant="flat"
          color="primary"
        >
          <div class="avatar-container d-flex">
            <v-avatar size="50" image="/images/profile/Loys.png" />
            <div class="icon-overlay d-flex justify-center align-center bg-primary">
              <span class="mdi mdi-help icon-help"></span>
            </div>
          </div>
        </v-btn>
      </div>
    </template>

    <!--TABS SHEET -->
    <v-sheet rounded="lg" width="520" elevation="11" style="margin-bottom: 18%;" >
      <div class="px-8 pt-6">
        <div class="d-flex align-center justify-space-between">
          <h6 class="text-h5 font-weight-semibold">Besoin d'aide ?</h6>
          <Icon
            icon="solar:close-circle-outline"
            height="30"
            width="30"
            class="cursor-pointer"
            @click="closeMenu"
          />
        </div>
      </div>

      <v-tabs v-model="activeTab" align-tabs="center" color="primary" class="mt-5" @mouseover="userInteraction.value = true">
        <v-tab value="0">
          <div class="d-flex align-center justify-center">
            <Icon
              icon="solar:question-circle-linear"
              height="15"
              width="15"
              class="cursor-pointer mr-1"
            />
            Aide de la page
          </div>
        </v-tab>
        <v-tab value="1">
          <div class="d-flex align-center justify-center">
            <Icon
              icon="solar:chat-round-dots-linear"
              height="15"
              width="15"
              class="cursor-pointer mr-1"
            />
            Contacter le support
          </div>
        </v-tab>
        <v-tab value="2">
          <div class="d-flex align-center justify-center">
            <Icon
              icon="solar:square-academic-cap-2-linear"
              height="15"
              width="15"
              class="cursor-pointer mr-1"
            />
            Webinaire
          </div>
        </v-tab>
      </v-tabs>

      <v-tabs-window v-model="activeTab">
        <v-tabs-window-item value="0">
          <LcFullVerticalHeaderHelpCenterHelpPage @contactTeam="activeTab = 1" />
        </v-tabs-window-item>
        <v-tabs-window-item value="1">
          <v-card>
            <LcFullVerticalHeaderHelpCenterHubspotForm />
          </v-card>
        </v-tabs-window-item>
        <v-tabs-window-item value="2">
          <v-card>
            <v-card-text>Informations sur le webinaire.</v-card-text>
          </v-card>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-sheet>
  </v-menu>
</template>

<style scoped>
.icon-overlay {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: none;
}

.icon-help {
  padding-right: 3px;
  padding-bottom: 1px;
}
</style>
