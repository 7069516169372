export const useAuth = () => {

  const { loggedIn, user, session, fetch, clear } = useUserSession()

  const logout = async () => {
    await clear();
    window.location.href = "/auth/login";
  };

  return {
    logout,
  };
};
