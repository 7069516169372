<script setup lang="ts">
import { defineProps, defineEmits } from "vue";
import { useAxios } from "@vueuse/integrations/useAxios";
import { ref, watch, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ClassUrl } from "@/class/url/ClassUrl";
import { ArchiveDTO } from "@/dto";

const isLoading = ref(true);
const archiveSelectedId = ref(0);
const archives = ref<ArchiveDTO[] | null>(null);
const isDisabled = ref(false);

defineProps({
    showItemTitle: {
        type: Boolean,
        default: true,
    },
});

const emit = defineEmits<{
   (event: 'update'): void;
}>();

const organizer = computed(() => useOrganizerStore().organizer);

async function loadArchive() {
   isLoading.value = true;
   const { data } = await useAxios(`/archive/organizer/${organizer.value.orgaId}`);

   if (data?.value) {
      archives.value = (data.value as ArchiveDTO[]).map((archive) => new ArchiveDTO(archive));
      sortArchives();
      setDefaultArchiveSelection();
   }

   isLoading.value = false;
}

function sortArchives() {
   if (!archives.value) return;
   archives.value.sort((a, b) => {
      if (a.status === "archive" && b.status !== "archive") return 1;
      if (a.status !== "archive" && b.status === "archive") return -1;
      return a.position - b.position;
   });
}

function setDefaultArchiveSelection() {
   const foundArchive = findArchiveByUrlParam();
   if (foundArchive) archiveSelectedId.value = foundArchive.id;
}

function findArchiveByUrlParam() {
   const archiveIdUrl = Number(ClassUrl.getUrlParams("archiveId")) || 0;
   return archives.value?.find((archive) => archive.id === archiveIdUrl) || null;
}

function updateRoute() {
   const archiveId = Number(ClassUrl.getUrlParams("archiveId")) || 0;
   if (archiveSelectedId.value === archiveId) return;

   const params = {
      "organizer-id": organizer.value.orgaId,
      "archive-id": archiveSelectedId.value,
   };

   const buyerOrganizerId = Number(ClassUrl.getUrlParams("buyerOrganizerId2")) || 0;
   if (buyerOrganizerId > 0) params["buyer-organizer-id"] = buyerOrganizerId;

   useRouter().push({ path: "/", query: params });
   emit("update");
}

function checkIsDisabled() {
   const url = window.location.href;
   isDisabled.value = ["workshop-duplicate-multiple", "workshop-creation-modification", "order-contract"].some((route) => url.includes(route));
}

loadArchive();

function archiveStatusIcon(status: string): string {
   return status === "visible" ? "mdi-eye-check-outline" :
      status === "draft" ? "mdi-eye-off-outline" :
         "mdi-folder";
}

watch(
   () => archiveSelectedId.value,
   updateRoute
);

const route = useRoute();
watch(
   () => route.params,
   () => {
      const archiveId = Number(new URLSearchParams(window.location.search).get("archive-id")) || 0;
      if (archiveSelectedId.value !== archiveId) archiveSelectedId.value = archiveId;
   }
);

watch(
   () => route.name,
   checkIsDisabled,
   { immediate: true }
);
</script>

<template>
   <div v-if="archives && archives.length > 0" id="select-archive">
      <v-select :items="archives" outlined dense :disabled="isDisabled" class="mx-1" label="Archive">

         <!-- Custom selection slot without icon -->
         <template #selection="{ item }">
            <span :class="{ 'd-none': !showItemTitle }">{{ item.title }}</span>
         </template>

         <!-- Custom item rendering slot with icon -->
         <template #item="{ props, item }">
            <v-list-item v-bind="props">
               <v-icon small class="mr-1">{{ archiveStatusIcon(item.raw.status) }}</v-icon>
               <span>{{ item.title }}</span>
            </v-list-item>
         </template>
      </v-select>
   </div>
</template>