<script setup lang="ts">
import { useCustomizerStore } from '@/stores/customizer';
const customizer = useCustomizerStore();

const title = ref("Viviarto - Tableau de bord Organisateur");
useHead({
    meta: [{ content: title }],
    titleTemplate: (titleChunk) => {
        return titleChunk
            ? `${titleChunk} - Tableau de bord Organisateur`
            : "Viviarto - Tableau de bord Organisateur";
    },
});


// USER & ORGANIZER
const { init } = useStoreInit();
init();

// SET URL
import addOrganizerParamsToUrl from "~/utils/AddParamsTopage";
addOrganizerParamsToUrl();

// CART
const getCart = computed(() => {
  return useCartStore();
});
</script>

<template>
        <v-app :theme="customizer.actTheme" :class="[
            customizer.actTheme,
            customizer.mini_sidebar ? 'mini-sidebar' : '',
            customizer.setHorizontalLayout ? 'horizontalLayout' : 'verticalLayout',
            customizer.setBorderCard ? 'cardBordered' : '',
        ]">

            <LcFullVerticalSidebar v-if="!customizer.setHorizontalLayout" />
            <div :class="customizer.boxed ? 'maxWidth' : 'full-header'">
                <LcFullVerticalHeader v-if="!customizer.setHorizontalLayout" />
            </div>

            <v-main>
                <div class="rtl-lyt  mb-3 hr-layout">
                    <v-container fluid class="page-wrapper bg-background px-sm-5 px-4  pt-12 rounded-xl">
                        <div class="">
                            <div :class="customizer.boxed ? 'maxWidth' : ''">
                                <NuxtPage />
                            </div>
                        </div>
                    </v-container>
                </div>
            </v-main>
            
            <!-- CTA Help-->
            <LcFullVerticalHeaderHelpCenter />
        </v-app>
</template>
