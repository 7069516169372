<script setup lang="ts">
import { structureDD } from "~/_api/headerData";
import { Icon } from "@iconify/vue";
import { defineEmits } from "vue";

const organizer = useOrganizerStore().organizer;
const { logout } = useAuth();

const isMenuOpen = ref(false);

const emit = defineEmits(["menu-state-changed"]);

watch(isMenuOpen, (value: any) => {
  emit("menu-state-changed", value);
});
</script>

<template>
  <!-- ---------------------------------------------- -->
  <!-- Structure Action DD -->
  <!-- ---------------------------------------------- -->
  <v-menu
    v-model="isMenuOpen"
    :close-on-content-click="true"
    class="profile_popup"
    location="end"
  >
    <template v-slot:activator="{ props }">
      <div
        class="custom-hover-primary text-left px-0 cursor-pointer d-flex align-center"
        variant="text"
        v-bind="props"
      >
        <Icon icon="solar:add-circle-line-duotone" height="30" width="30" />
      </div>
    </template>
    <v-sheet rounded="lg" width="300" elevation="10">
      <div class="px-8 pt-6">
        <div class="d-flex align-center justify-space-between">
          <h6 class="text-h5 font-weight-semibold">Ma structure</h6>
          <NuxtLink
            to="/dashboard/organizer/edit"
            class="custom-hover-primary text-left px-0 cursor-pointer"
          >
            <Icon
              icon="solar:pen-linear"
              height="23"
              width="23"
              style="color: black"
            />
          </NuxtLink>
        </div>
      </div>
      <v-list class="py-2 theme-list" lines="one">
        <v-list-item
          v-for="item in structureDD"
          :key="item.title"
          class="py-4 px-8 custom-text-primary cursor-pointer"
          :to="item.href"
        >
          <template v-slot:prepend>
            <v-avatar
              size="40"
              class="rounded-lg"
              :class="'bg-light' + item.bgcolor"
            >
              <Icon
                :icon="'solar:' + item.avatar"
                width="25"
                height="25"
                :class="'text-' + item.bgcolor"
              />
            </v-avatar>
          </template>

          <h6 class="text-h6 font-weight-medium mb-1 custom-title">
            {{ item.title }}
          </h6>
        </v-list-item>
      </v-list>
      <div class="pb-6 px-8 text-center">
        <v-btn
          :color="organizer.color"
          @click="logout()"
          size="large"
          rounded="pill"
          block
          >Logout</v-btn
        >
      </div>
    </v-sheet>
  </v-menu>
</template>