<script setup lang="ts">
import { ref, type Ref, reactive } from "vue";
import { useAxios } from '@vueuse/integrations/useAxios'
import { PaymentTypeDTO } from "@/dto/PaymentTypeDTO";
import { ClassErrorHandler } from '@/class/ClassErrorHandler'
import { PublicationDTO } from '@/dto/PublicationDTO'
import BookingRestriction from "@/components/booking/restriction/BookingRestriction.vue";

const paymentTypes: Ref<PaymentTypeDTO[]> = ref([]);
const paymentTypesRestricted: Ref<PaymentTypeDTO[]> = ref([]);
const publication: PublicationDTO = reactive(new PublicationDTO())

async function loadPublicationPaymentType() {

  try {
    await getPublicationPaymentType()

    if (publication.message === '') getPaymentTypeRestricted()

  } catch (error) {
    const errorUtil = new ClassErrorHandler(error)
    errorUtil.displayErrorIfDev()

  }
}
loadPublicationPaymentType()

if (window.location.pathname.includes("page-presentation")) loadPublicationPaymentType()

async function getPublicationPaymentType() {
  const urlAxios = `/publication/organizer/` + cart().orgaId

  const { execute, data } = useAxios(urlAxios, { method: 'GET' }, { immediate: false })
  await execute({ data: { type: 'payment-type' } })

  if (!data.value) {
    return
  }

  data.value.forEach((publicationItem: PublicationDTO) => {

    if (publicationItem.type !== "payment-type") return;

    Object.assign(publication, new PublicationDTO(publicationItem))

  });
}

async function getPaymentTypeRestricted() {

  const urlAxios = '/payment-type/from-cart-prevention/'

  const { execute, data } = useAxios(urlAxios, { method: 'GET' }, { immediate: false })
  await execute({ params: { cartPublicId: cart().publicId } })

  if (!data || !Array.isArray(data.value)) {
    return;
  }

  data.value.forEach((paymentTypeCurrent) => {
    const paymentType = new PaymentTypeDTO(paymentTypeCurrent);

    if (paymentType.title === "Pas de paiement") return

    paymentTypes.value.push(paymentType)
  }
  );
}

function cart() {
  return useCartStore().cart;
}

</script>

<template>
  <div>
    <div v-if="publication.message !== ''">
      <span class="text-area " v-html="publication.message"/>
    </div>

    <div v-else>
      <div v-for="(paymentType, index) in paymentTypes" :key="index">{{ paymentType.title }}</div>

      <div v-if="paymentTypesRestricted.length > 0" class="mt-2">
        Les moyens ci-dessous sont restreints par la structure. Demandez l'accès avant d'être bloqué.
      </div>

      <BookingRestriction
v-if="paymentTypesRestricted.length > 0" class="mt-3 pa-0"
        :payment-types="paymentTypesRestricted" :is-text-display="false" />
    </div>
  </div>
</template>

<style scoped>
.text-area :deep(ul) , .text-area :deep(ol) {
  margin-left: 20px !important;
}

.text-area :deep(p) {
  margin-bottom: 0px !important;
}

</style>