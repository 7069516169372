<script setup lang="ts">
import { Icon } from "@iconify/vue";
import { ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { DateHelperV3 } from "@/class/DatesV3";
import { CartProductControllerDelete } from "@/class/cart/controller/CartProductDelete";
import { CartProductFrontEnd } from "@/dto/cart/CartProductDTO";
import OrganizerLightButtonBase from "@/ui/frontOrganizer/OrganizerLightButtonBase.vue";
import PaymentTypeInfo from "@/components/booking/step0/PaymentTypeInfo.vue";

// Stores
const router = useRouter();
const activeTab = ref(0);
const isMenuOpen = ref(false);
const productCounter = ref(1);
const cartStore = useCartStore();
const organizerStore = useOrganizerStore();
const isOpen = ref(false);

// Computed properties
const products = computed(() => cartStore.products);
const cart = computed(() => cartStore.cart);

// Close menu
const closeMenu = () => {
  isMenuOpen.value = false;
};

// Remove product
const removeProduct = async (product: CartProductFrontEnd) => {

  const productController = new CartProductControllerDelete(product);
  // productController.setShowToast(true);
  await productController.deleteProduct();
  productCounter.value++;
};

// Navigate to booking
const navigateToBooking = () => {
  if (products.value.length === 0) return;

  // test if url contains daily/inscription - if yer cartStore.isVisible = false else redirect to booking-participant
  if (router.currentRoute.value.path.includes("/dashboard/daily/inscription")) {
    isOpen.value = false;
    cartStore.isPlanningToDisplay = false;
    return;
  }

  if (router.currentRoute.value.path === "booking-participant") {
    router.push({ name: "booking-participant" });
    return;
  }

  router.push(
    { name: "booking-participant",
     query: { "public-id": cart.value.publicId } 
    });
};

// Generate product label
const generateProductLabel = (product) => {
  let label = product.label;
  if (product.priceRelationId > 0) label += ` - ${product.priceAmount} €`;
  if (product.studentGeneric?.id > 0) label += ` | ${product.studentGeneric.firstName} ${product.studentGeneric.lastName}`;
  if (product.productDynamicNumber > 0 && product.datetimeFirst) {
    label += ` | Début le ${DateHelperV3.getDateDirectly(product.datetimeFirst, "DD/MM/YYYY HH:mm")}`;
  }
  return label;
};

// Watch cart changes
watch(
  () => products.value.length,
  (newLength) => {
    if (newLength === 0 && cartStore.isPlanningToDisplay) {
      cartStore.isPlanningToDisplay = false;
    }
  }
);
</script>

<template>
  <v-menu v-model="isOpen" :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <v-btn icon variant="text" v-bind="props" class="me-4">
        <v-badge color="primary" :content="products.length" offset-x="-4" offset-y="-6">
          <Icon icon="solar:cart-3-line-duotone" height="24" width="24" />
        </v-badge>
      </v-btn>
    </template>

    <v-card class="mx-auto" elevation="4" max-width="400">
      <v-card-title class="pa-4">Panier</v-card-title>

      <v-card-subtitle class="text-body-1 bg-surface-light pa-2 pl-4">
        Votre sélection
      </v-card-subtitle>

      <v-card-text class="pa-4">
        <div v-if="products.length < 1">
          <div class="text-body-2">Aucune activité</div>
        </div>
        <div v-else >
          <div v-for="product in products" :key="product.storeUuid" class="d-flex">
            <div>
            {{ generateProductLabel(product) }}
          </div>
            <v-btn icon small class="py-1" @click="removeProduct(product)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>

      <div v-if="products.length > 0">
        <v-card-subtitle class="text-body-1 bg-surface-light pa-2 pl-4">
          Moyen de paiement
        </v-card-subtitle>
        <v-card-text class="pa-4">
          <PaymentTypeInfo />
        </v-card-text>
      </div>

      <v-card-subtitle class="text-body-1 bg-surface-light pa-2 pl-4">
        Vous réservez pour 2 participants différents ?
      </v-card-subtitle>
      <v-card-text class="pa-4">
          <div>
            <v-icon small color="grey">
              mdi-alert
            </v-icon>
            1 panier = 1 participant
          </div>

          <div class="mt-1">
            Si vous voulez inscrire 2 personnes : inscrivez la 1ère personne à tous ses cours, suivez les étapes jusqu'à
            la
            page de confirmation de son inscription, puis revenez sur le planning pour inscrire la 2ème personne à son
            tour.
            L'inscription de plusieurs personnes d'un seul coup n'est pas possible.
          </div>
      </v-card-text>

      <v-card-actions>

        <v-row dense class="mt-2">
          <v-col cols="5" class="pa-0">
            <v-btn plain class="pa-1 ma-1">
              <v-icon>mdi-plus-circle</v-icon>
              Ajouter une activité
            </v-btn>
          </v-col>
          <v-col cols="7" class="pa-0  text-right">
            <OrganizerLightButtonBase :disabled="!products.length" elevation="2" rounded class="pa-3 body-1 mr-1"
              style="min-width:150px;" @click="navigateToBooking">
              {{ products.length ? "Réserver" : "Aucune activité" }}
            </OrganizerLightButtonBase>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

  </v-menu>
</template>
