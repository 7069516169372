<script setup lang="ts">
import { ref, watch, onBeforeMount } from "vue";
import { useCustomizerStore } from "@/stores/customizer";
import ShoppingCart from "../vertical-header/ShoppingCart.vue";

import { Icon } from "@iconify/vue";
const customizer = useCustomizerStore();
const priority = ref(customizer.setHorizontalLayout ? 0 : 0);
const stickyHeader = ref(false);

watch(priority, (newPriority: any) => {
  priority.value = newPriority;
});

//For on Scroll Effect on Header
onBeforeMount(() => {
  window.addEventListener("scroll", handleScroll);
});

function handleScroll() {
  if (window.pageYOffset) {
    stickyHeader.value = true;
  } else {
    stickyHeader.value = false;
  }
}

const getCart = computed(() => {
  return useCartStore();
});
</script>

<template>
  <v-app-bar
    elevation="0"
    :priority="priority"
    height="75"
    color="containerBg"
    id="top"
    :class="stickyHeader ? 'sticky' : ''"
  >
    <v-btn
      class="hidden-md-and-down"
      icon
      variant="text"
      size="small"
      @click.stop="customizer.SET_MINI_SIDEBAR(!customizer.mini_sidebar)"
    >
      <Icon icon="solar:list-bold-duotone" height="24" width="24" />
    </v-btn>
    <v-btn
      class="hidden-lg-and-up"
      icon
      variant="text"
      @click.stop="customizer.SET_SIDEBAR_DRAWER"
      size="small"
    >
      <Icon icon="solar:list-bold-duotone" height="24" width="24" />
    </v-btn>

    <!-- ---------------------------------------------- -->
    <!-- Logo -->
    <!-- ---------------------------------------------- -->
    <div class="mx-5">
      <div class="logo">
        <NuxtLink to="/">
          <img src="/images/logos/logo-v.png" />
        </NuxtLink>
      </div>
    </div>
    <!-- ---------------------------------------------- -->
    <!-- Search part -->
    <!-- ---------------------------------------------- -->
    <LcFullVerticalHeaderSearchbar />
    <v-spacer />
    <!-- ---------------------------------------------- -->
    <!-- ShoppingCart -->
    <!-- ---------------------------------------------- -->
    <ShoppingCart />
    <!-- ---------------------------------------------- -->
    <!-- School btn -->
    <!-- ---------------------------------------------- -->
    <LcFullVerticalHeaderSchoolButton />
    <!-- ---------------------------------------------- -->
    <!-- User Profile -->
    <!-- ---------------------------------------------- -->
    <LcFullVerticalHeaderProfileDD />
  </v-app-bar>
</template>
