import type { menu } from "../horizontal-sidebar/horizontalItems";

const sidebarItem: menu[] = [
  { header: "Quotiden" },
  {
    title: "sidebar.planning",
    icon: "calendar-line-duotone",
    BgColor: "primary",
    to: "/dashboard/daily/planning/",
  },
  {
    title: "sidebar.MParticipants",
    icon: "users-group-rounded-line-duotone",
    BgColor: "primary",
    to: "/dashboard/daily/participants",
  },
  {
    title: "sidebar.Inscription",
    icon: "user-plus-line-duotone",
    BgColor: "primary",
    to: "/dashboard/daily/inscription",
  },
  {
    title: "sidebar.payment-on-site",
    icon: "banknote-2-line-duotone",
    BgColor: "primary",
    to: "/dashboard/daily/payment-on-place",
    
  },
  { header: "Gestion" },
  {
    title: "sidebar.Participants",
    icon: "users-group-rounded-line-duotone",
    BgColor: "primary",
    to: "#",
    id:0,
    children: [
      {
        title: "sidebar.emails-sms",
        to: "/dashboard/gestion/sms-email",
      },
      {
        title: "sidebar.attendance-list",
        to: "/dashboard/gestion/checkin",
      },
      {
        title: "sidebar.manage-seats",
        to: "/dashboard/gestion/quantity",
      },
      {
        title: "sidebar.access-rights",
        to: "/dashboard/gestion/restriction-access",
      },
      {
        title: "sidebar.epass-purchases",
        to: "/dashboard/gestion/epass-buyers/",
      },
      {
        title: 'sidebar.complete-products-list',
        to: '/dashboard/gestion/order-items/'
      }
    ],
  },
  {
    title: "sidebar.Finance",
    icon: "dollar-line-duotone",
    BgColor: "primary",
    to: "#",
    id:1,
    children: [
      {
        title: "sidebar.payment-tracking",
        to: "/dashboard/finance/order-payments-export",
      },
      {
        title: "sidebar.invoices-credits",
        to: "/dashboard/finance/factures-tableau/",
      },
      {
        title: "sidebar.unpaid-online",
        to: "/dashboard/finance/unpaid",
      },
      {
        title: "sidebar.contract-amount-error",
        to: "/dashboard/finance/order-amount-trouble",
      },
      {
        title: "sidebar.payout-tracking",
        to: "/dashboard/finance/payouts-exports",
      },
      {
        title: "sidebar.financial-summary",
        to: "/dashboard/finance/global",
      },
      // {
      //   title: 'sidebar.global-financial-summary',
      //   to: '/dashboard/finance/global'
      // },
      // {
      //   title: 'sidebar.detailed-financial-summary',
      //   to: '/dashboard/finance/detail'
      // },
      {
        title: "sidebar.stripe-account",
        to: "/dashboard/finance/stripe",
      },
    ],
  },
  {
    title: "sidebar.Settings",
    icon: "settings-line-duotone",
    BgColor: "secondary",
    to: "#",
    id:2,
    children: [
      {
        title: "sidebar.payment-method",
        to: "/dashboard/settings/payment-type",
      },
      {
        title: "sidebar.payment-terms",
        to: "/dashboard/settings/modalities",
      },
      {
        title: "sidebar.Prices",
        to: "/dashboard/settings/price",
      },
      {
        title: "sidebar.reference-calendar",
        to: "/dashboard/settings/calendar-reference",
      },
      {
        title: "sidebar.registration-forms",
        to: "/dashboard/settings/registration-form",
      },
      {
        title: "sidebar.trainer-schedule",
        to: "/dashboard/settings/former-planning",
      },
      {
        title: "sidebar.Location",
        to: "/dashboard/settings/place",
      },
      {
        title: "sidebar.Epass",
        to: "/dashboard/settings/epass",
      },
      {
        title: "sidebar.discount-code",
        to: "/dashboard/settings/discount-code",
      },
      {
        title: "sidebar.additional-products",
        to: "/dashboard/settings/products",
      },
      {
        title: "sidebar.archiving-season",
        to: "/dashboard/settings/archive",
      },
      {
        title: "sidebar.schedule-verification",
        to: "/dashboard/settings/verification-tool",
      },
    ],
  },

  // --------
  // { header: "------------------" },

  // {
  //   title: 'sidebar.planning',
  //   icon: 'double-alt-arrow-down-bold-duotone',
  //   BgColor: 'secondary',
  //   to: '#',
  //   children: [
  //     {
  //       title: 'sidebar.planning',
  //       to: '/dashboard/planning/planning-workshops/'
  //     },
  //     {
  //       title: 'sidebar.create-workshop',
  //       to: '/dashboard/workshop/create-edit'
  //     },
  //     {
  //       title: 'sidebar.manage-seats',
  //       to: '/dashboard/planning/quantity'
  //     },
  //     {
  //       title: 'sidebar.waiting-list',
  //       to: '/dashboard/planning/waiting'
  //     },
  //     {
  //       title: 'sidebar.access-rights',
  //       to: '/dashboard/restriction-access'
  //     }
  //   ]
  // },
  // {
  //   title: 'sidebar.Participants',
  //   icon: 'double-alt-arrow-down-bold-duotone',
  //   BgColor: 'secondary',
  //   to: '#',
  //   children: [
  //     {
  //       title: 'sidebar.Participants',
  //       to: '/dashboard/participants'
  //     },
  //     {
  //       title: 'sidebar.emails-sms',
  //       to: '/dashboard/participants/sms-email'
  //     },
  //     {
  //       title: 'sidebar.attendance-list',
  //       to: '/dashboard/participants/checkin'
  //     },
  //     {
  //       title: 'sidebar.epass-purchases',
  //       to: '/dashboard/participants/epass-buyers/'
  //     },
  //     {
  //       title: 'sidebar.complete-products-list',
  //       to: '/dashboard/participants/order-items/'
  //     }
  //   ]
  // },
  // {
  //   title: 'sidebar.buyer-registrations',
  //   icon: 'double-alt-arrow-down-bold-duotone',
  //   BgColor: 'secondary',
  //   to: '#',
  //   children: [
  //     {
  //       title: 'sidebar.search-register',
  //       to: '/dashboard/buyer-organizer/search'
  //     },
  //     {
  //       title: 'sidebar.payment-on-site',
  //       to: '/dashboard/buyer-organizer/payment-on-place'
  //     }
  //   ]
  // },
  // {
  //   title: 'sidebar.Finance',
  //   icon: 'double-alt-arrow-down-bold-duotone',
  //   BgColor: 'secondary',
  //   to: '#',
  //   children: [
  //     {
  //       title: 'sidebar.payment-tracking',
  //       to: '/dashboard/finance/order-payments-export'
  //     },
  //     {
  //       title: 'sidebar.invoices-credits',
  //       to: '/dashboard/factures-tableau/'
  //     },
  //     {
  //       title: 'sidebar.unpaid-online',
  //       to: '/dashboard/finance/unpaid'
  //     },
  //     {
  //       title: 'sidebar.contract-amount-error',
  //       to: '/dashboard/finance/order-amount-trouble'
  //     },
  //     {
  //       title: 'sidebar.payout-tracking',
  //       to: '/dashboard/finance/payouts-exports'
  //     },
  //     {
  //       title: 'sidebar.global-financial-summary',
  //       to: '/dashboard/finance/global'
  //     },
  //     {
  //       title: 'sidebar.detailed-financial-summary',
  //       to: '/dashboard/finance/detail'
  //     },
  //     {
  //       title: 'sidebar.stripe-account',
  //       to: '/dashboard/finance/stripe'
  //     }
  //   ]
  // },
  // {
  //   title: 'sidebar.Settings',
  //   icon: 'double-alt-arrow-down-bold-duotone',
  //   BgColor: 'secondary',
  //   to: '#',
  //   children: [
  //     {
  //       title: 'sidebar.payment-method',
  //       to: '/dashboard/settings/payment-type'
  //     },
  //     {
  //       title: 'sidebar.payment-terms',
  //       to: '/dashboard/settings/modalities'
  //     },
  //     {
  //       title: 'sidebar.Prices',
  //       to: '/dashboard/settings/price'
  //     },
  //     {
  //       title: 'sidebar.reference-calendar',
  //       to: '/dashboard/settings/calendar-reference'
  //     },
  //     {
  //       title: 'sidebar.registration-forms',
  //       to: '/dashboard/settings/registration-form'
  //     },
  //     {
  //       title: 'sidebar.trainer-schedule',
  //       to: '/dashboard/settings/former-planning'
  //     },
  //     {
  //       title: 'sidebar.Location',
  //       to: '/dashboard/settings/place'
  //     },
  //     {
  //       title: 'sidebar.Epass',
  //       to: '/dashboard/epass'
  //     },
  //     {
  //       title: 'sidebar.discount-code',
  //       to: '/dashboard/settings/discount-code'
  //     },
  //     {
  //       title: 'sidebar.additional-products',
  //       to: '/dashboard/settings/products'
  //     },
  //     {
  //       title: 'sidebar.archiving-season',
  //       to: '/dashboard/settings/archive'
  //     },
  //     {
  //       title: 'sidebar.schedule-verification',
  //       to: '/dashboard/settings/verification-tool'
  //     }
  //   ]
  // },
  // {
  //   title: 'sidebar.my-structure',
  //   icon: 'double-alt-arrow-down-bold-duotone',
  //   BgColor: 'secondary',
  //   to: '#',
  //   children: [
  //     {
  //       title: 'sidebar.edit-info',
  //       to: '/dashboard/organizer/edit'
  //     },
  //     {
  //       title: 'sidebar.qr-code',
  //       to: '/dashboard/organizer/qr-code'
  //     },
  //     {
  //       title: 'sidebar.Statistics',
  //       to: '/dashboard/organizer/statistics'
  //     }
  //   ]
  // }
];

export default sidebarItem;
