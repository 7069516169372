export class PublicationDTO {

    public id = 0
    public orgaId = 0
    public type = ''
    public title = ''
    public message = ''
    public date = ''
    public order = 0
    public draft = 0

    readonly TYPE_FAQ = 'faq'
    readonly TYPE_NEWS = 'news'

    public constructor(init?: Partial<PublicationDTO>) {
        if (!init) return
        Object.assign(this, init)
      }
}
